import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { Loading } from '../Loading';

type FormData = {
  name: string;
  addMembersFromOrgMembers: boolean;
};

export function CreateChannelModal(props: {
  orgId: string;
  onCancel: () => void;
  onComplete: () => void;
}): JSX.Element {
  const { orgId } = props;
  const { register, handleSubmit, formState, setFocus } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      addMembersFromOrgMembers: true,
    },
  });

  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback(
      async (data: FormData) => {
        const resp = await apiService.channel.createChannel({
          orgId,
          name: data.name,
          exGroupId: null,
          addMembersFromOrgMembers: data.addMembersFromOrgMembers,
        });
        return resp.data.channel;
      },
      [orgId]
    )
  );

  const onSubmit = handleSubmit(async (data: FormData) => {
    const channel = await call(data);
    if (!channel) return;
    props.onComplete();
  });

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  return (
    <ModalWrapper containerClassName='w-160' borderStyle='gray'>
      <div className='w-full h-full min-h-52 flex flex-col items-center justify-center px-16 py-10'>
        <header className='font-medium text-2xl flex flex-col items-center'>
          Create Channel
        </header>
        <section className='w-full my-10'>
          <label htmlFor='name' className='flex flex-col'>
            <p className='font-bold'>
              Name<span className='text-primary'>(Required)</span>
            </p>
            <input
              className={`w-full h-12.5 ${
                formState.errors.name ? 'field-error' : 'field'
              }`}
              placeholder='Name the channel'
              {...register('name', {
                required: true,
              })}
            />
          </label>
          <label htmlFor='addAll' className='flex items-center justify-start'>
            <input
              type='checkbox'
              className='checkbox-dark mr-1'
              {...register('addMembersFromOrgMembers')}
            />
            <div className='text-sms text-secondary'>
              Add all Org Members as Participants
            </div>
          </label>
          {error && (
            <div className='text-red-002 text-sms mt-2'>{err2s(error)}</div>
          )}
        </section>
        <footer className='w-full flex items-center justify-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 h-10 flex items-center justify-center'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 h-10 flex items-center justify-center'
            disabled={state.isRunning}
            onClick={onSubmit}
          >
            {state.isRunning && <Loading text='' />}
            <div className='ml-2'>Save</div>
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}
