import { Route, Routes } from '@remix-run/react';
import { Fragment } from 'react';

import { type Organization } from '../../types';
import { OrgFeatureProvider } from '../Organization';
import { Index } from './ChannelList';
import { ChannelLayout } from './Layout';
export { ChannelMemberAvatar } from './ChannelMemberAvatar';
export { ChannelMemberPicker } from './ChannelMemberPicker';
export * from './Slack/OneTimeInstall';
export { SlackActionButton } from './Slack/SlackActionButton';
export * from './Slack/SlackChannelBindingModal';
export * from './Slack/SlackConnectModal';
export type {
  SlackBindingStepConfigOverride,
  SlackModalBinding,
} from './Slack/config';
export { useSlackInstalledCallbackModal } from './Slack/useSlackInstalledCallbackModal';
export { SyncChannelMemberButton } from './SyncChannelMemberButton';
export {
  useInviteUsers,
  useInviteUsersFromChannel,
} from './hooks/useInviteUsers';
export { useTriggerSlackManageUserModal } from './Slack/SlackManageUserModal';
export { ChannelUtils } from './utils';
export { useTriggerAddMembersFromOrgModal } from './AddMembersFromOrgModal';
export { useChannelPerm } from './hooks/useChannelPerm';
export { useCheckSlackScopes } from './hooks/useInstallation';
export { useTriggerSlackAppUpgradeModal } from './Slack/SlackChannelBindingModal';

// eslint-disable-next-line import/no-default-export
export default function Home(props: {
  org: Organization;
  routePrefix: string;
  withLayout?: boolean;
  adminMode?: boolean;
}): JSX.Element {
  const Layout = props.withLayout ? ChannelLayout : Fragment;
  return (
    <OrgFeatureProvider {...props}>
      <Layout>
        <Routes>
          <Route index element={<Index />} />
        </Routes>
      </Layout>
    </OrgFeatureProvider>
  );
}
