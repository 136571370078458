import { useCallback, useState } from 'react';
import { match } from 'ts-pattern';

import { type Organization } from '../../types';
import { type Program } from '../../types/program';
import { err2s } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { Loading } from '../Loading';
import { ProgramCard } from './ProgramCard';
import { ProgramDetailCard } from './ProgramDetailCard';
import { useActivePrograms } from './usePrograms';

export function ProgramPicker(props: {
  org: Organization;
  onClick: () => void;
  onClose: () => void;
}): JSX.Element {
  const swr = useActivePrograms();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const [muted, setMuted] = useState(true);

  const onClick = useCallback(
    async (program: Program) => {
      props.onClick();
      await triggerModal({
        kind: 'custom',
        containerClassName: 'bg-black bg-opacity-60',
        element: (p) => {
          const handleClose = () => {
            props.onClose?.();
            p.internalOnCancel();
          };

          return (
            <ProgramDetailCard
              org={props.org}
              program={program}
              onConfirm={p.internalOnConfirm}
              onClose={handleClose}
              onClickChannel={handleClose}
            />
          );
        },
      });
    },
    [props, triggerModal]
  );

  return (
    <div className='w-230 max-h-[90%]'>
      <div className='relative w-full h-full overflow-y-auto scrollbar bg-modal rounded-xl p-10 flex flex-col gap-10'>
        <header className='flex items-start justify-between'>
          <div>
            <div className='text-white font-bold text-3.5xl'>
              Available Programs
            </div>
            <div className='text-icon-gray text-xl font-medium'>
              Drive engagement across your team with our incredible programs
            </div>
          </div>
          <button
            type='button'
            className='btn-secondary w-30 h-10'
            onClick={() => props.onClose()}
          >
            Cancel
          </button>
        </header>
        {match(swr)
          .when(
            () => swr.isLoading,
            () => <Loading />
          )
          .when(
            () => !!swr.error,
            () => (
              <div className='text-sms text-red-002'>{err2s(swr.error)}</div>
            )
          )
          .otherwise(() => (
            <div className='flex items-center flex-wrap gap-10'>
              {swr.data?.map((p) => (
                <ProgramCard
                  key={p.type}
                  program={p}
                  onClick={() => onClick(p)}
                  muted={muted}
                  setMuted={setMuted}
                />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
}

export function useProgramPicker(): (org: Organization) => Promise<void> {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useCallback(
    async (org: Organization) => {
      await triggerModal({
        kind: 'custom',
        containerClassName: 'bg-black bg-opacity-60',
        element: (p) => (
          <ProgramPicker
            org={org}
            onClick={p.internalOnConfirm}
            onClose={p.internalOnCancel}
          />
        ),
      });
    },
    [triggerModal]
  );
}
