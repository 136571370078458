import { useLocation, useNavigate } from '@remix-run/react';
import { useRef } from 'react';

import {
  type DtoChannel,
  type DtoProgram,
} from '@lp-lib/api-service-client/public';

import { type Program } from '../../components/Program';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { VideoViewer } from '../../pages/Media';
import { type Organization } from '../../types';
import { fromMediaDTO } from '../../utils/api-dto';
import { MediaUtils } from '../../utils/media';
import { ChannelMemberAvatar, ChannelUtils } from '../Channel';
import { useChannels } from '../Channel/hooks/useChannels';
import { ArrowRightIcon } from '../icons/Arrows';
import { CloseIcon } from '../icons/CloseIcon';
import {
  OrgUtils,
  useMyOrganizationFeatureChecker,
  useOrgConnection,
} from '../Organization';
import { OrgSubscriptionUpgradeButton } from '../Organization/OrgSubscription';
import { SlackInstallButton } from '../Slack';
import { UseProgramButton } from './UseProgramButton';

function Background(props: { bgImageUrl: string }): JSX.Element {
  return (
    <div className='absolute w-full aspect-w-16 aspect-h-9 z-0 top-0'>
      <img
        src={props.bgImageUrl}
        alt='background'
        className='w-full h-full rounded-xl'
      />
      <div className='absolute w-full h-full rounded-xl bg-gradient-to-r from-lp-black-001 to-transparent'></div>

      <div className='absolute w-full h-full rounded-xl bg-gradient-to-b from-transparent to-black'></div>
    </div>
  );
}

function ChannelItem(props: {
  channel: DtoChannel;
  org: Organization;
  program: DtoProgram;
  onClick?: () => void;
}): JSX.Element {
  const { channel, org, program } = props;
  const connTypeInfo = OrgUtils.GetConnTypeInfo(org, channel.exConnectType);
  const Icon = connTypeInfo.icon;
  const creator = channel.creator;
  const navigate = useNavigate();

  const onClick = () => {
    const link = channel.programLinks.find(
      (l) => l.programId === program.id || l.programType === program.type
    );
    if (!link) return;
    navigate(`/programs/${link.id}`);
    props.onClick?.();
  };

  return (
    <div className='flex items-center gap-2 rounded-xl border border-secondary bg-modal px-4 py-5'>
      <div className='w-5 h-5 mr-3'>
        <Icon />
      </div>
      <div className='font-bold'>{ChannelUtils.ChannelName(channel, org)}</div>
      <div className='text-sms italic'>{channel.membersCount} People</div>
      <div className='ml-auto'></div>
      <div className='flex items-center justify-start'>
        <ChannelMemberAvatar
          exConnectType={channel.exConnectType}
          hideIfNoExConnection
          member={channel.creator}
        />
        <div className={`flex flex-col items-start`}>
          <div className='text-sms'>
            {!!channel.exConnectType
              ? `${creator.fullName} (Owner)`
              : 'Created by Luna Park'}
          </div>
        </div>
      </div>
      <button type='button' className='btn w-3 h-3 ml-4' onClick={onClick}>
        <ArrowRightIcon />
      </button>
    </div>
  );
}

export function ProgramDetailCard(props: {
  org: Organization;
  program: Program;
  onConfirm: () => void;
  onClose: () => void;
  onClickChannel?: (channel: DtoChannel) => void;
}): JSX.Element {
  const { org, program } = props;
  const location = useLocation();
  const orgId = org.id;
  const { connection } = useOrgConnection({
    orgId: orgId,
    immutable: true,
  });

  const programsAccess = useMyOrganizationFeatureChecker().canAccessProgram(
    program.id
  );
  const { data } = useChannels(org.id);
  const channels = data ?? [];

  const usedChannels = channels.filter((c) =>
    c.programLinks.find(
      (l) =>
        l.status === 'active' &&
        (l.programId === program.id || l.programType === program.type)
    )
  );

  const iconUrl = program.basicSettings?.icon?.media?.url;
  const trailerVideoUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(program.basicSettings?.trailer?.media)
  );
  const trailerPosterUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(program.basicSettings?.trailer?.media),
    {
      videoThumbnail: 'first',
    }
  );
  const coverImageUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(program.basicSettings?.cover?.media)
  );
  const bgImageUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(program.basicSettings?.background?.media)
  );

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, props.onClose, undefined);

  return (
    <div ref={ref} className='min-w-160 w-5/6 max-w-[1024px] h-5/6'>
      <div className='relative w-full h-full overflow-y-auto scrollbar bg-black rounded-xl border border-secondary'>
        <Background bgImageUrl={bgImageUrl ?? coverImageUrl ?? ''} />
        <div className='relative text-white w-full h-full z-5 flex flex-col'>
          <button
            type='button'
            className='btn absolute right-3 top-3 w-6 h-6 rounded-full flex justify-center items-center border border-secondary bg-black'
            onClick={props.onClose}
          >
            <CloseIcon className='w-2 h-2 fill-current' />
          </button>

          <div className='relative mx-7.5 py-6 border-b border-secondary'>
            <div className='absolute bottom-0 left-0'>
              <button
                type='button'
                className={`btn rounded-none font-bold border-b-2 border-white cursor-default`}
                disabled={false}
              >
                Program Details
              </button>
            </div>

            <div className='h-80 flex flex-col'>
              <div className='flex items-center'>
                {iconUrl && (
                  <img src={iconUrl} alt='icon' className='w-8 h-8 mr-2' />
                )}
                <div className='text-white text-2xl font-medium'>
                  {program.name}
                </div>
              </div>
              <div className='flex items-end justify-between mt-5'>
                <div
                  className='w-100 rounded-xl overflow-hidden'
                  style={{
                    aspectRatio: '16 / 9',
                  }}
                >
                  {trailerVideoUrl ? (
                    <VideoViewer
                      media={{
                        videoUrl: trailerVideoUrl,
                        posterUrl: trailerPosterUrl ?? undefined,
                      }}
                    />
                  ) : (
                    <div className='aspect-w-16 aspect-h-9'>
                      <img
                        src={coverImageUrl ?? undefined}
                        className={`object-cover rounded-xl flex-shrink-0 w-full h-full`}
                        alt='cover'
                      />
                    </div>
                  )}
                </div>
                {!programsAccess ? (
                  <OrgSubscriptionUpgradeButton />
                ) : connection?.status === 'active' ? (
                  <UseProgramButton
                    org={org}
                    program={program}
                    onClick={props.onConfirm}
                  />
                ) : (
                  <SlackInstallButton from={location.pathname} />
                )}
              </div>
            </div>
          </div>
          <div className='px-7.5 py-5 text-sms whitespace-pre-wrap'>
            {program.basicSettings?.longDescription}
          </div>
          {usedChannels.length > 0 && (
            <div className='px-7.5 pb-5 flex flex-col gap-2'>
              <div className='text-sms'>Actively Used By:</div>
              <div className='flex flex-col gap-2'>
                {usedChannels.map((c) => (
                  <ChannelItem
                    key={c.id}
                    channel={c}
                    org={org}
                    program={program}
                    onClick={() => props.onClickChannel?.(c)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
